import { Directive, ElementRef, Optional } from '@angular/core';
import { FormControlDirective, NgControl } from '@angular/forms';

@Directive({
  selector: '[afwInput]',
  standalone: true,
})
export class InputDirective {
  constructor(
    public elementRef: ElementRef,
    @Optional() public control: NgControl,
  ) {}
}
