import {
  AfterContentInit,
  booleanAttribute,
  Component,
  ContentChild, Directive,
  ElementRef,
  HostBinding,
  inject,
  input, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDirective } from '../input/input.directive';
import { PrefixDirective } from '../input/prefix.directive';
import { SuffixDirective } from '../input/suffix.directive';
import { Validators } from '@angular/forms';

@Directive({
  selector: 'afw-label, label[afwLabel]',
  standalone: true
})
export class AfwLabelDirective {}

@Component({
  selector: 'afw-form-field',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements AfterContentInit {

  @HostBinding('class')
  hostClass = 'afw-form-field';

  @ContentChild(InputDirective)
  afwInput!: InputDirective;

  @ContentChild(PrefixDirective)
  prefix!: PrefixDirective;

  @ContentChild(SuffixDirective)
  suffix!: SuffixDirective;

  @ViewChild('formWrapper')
  formWrapper!: ElementRef;

  @ViewChild('content')
  content!: ElementRef;

  protected required: boolean = false;

  withError = input(true, {transform: booleanAttribute})

  public elementRef: ElementRef = inject(ElementRef);

  ngAfterContentInit() {
    this.required = this.afwInput?.control?.control?.hasValidator(Validators.required) || this.afwInput?.elementRef.nativeElement.hasAttribute('required') || false;

    this.configureControlByAttributes();
  }

  private configureControlByAttributes() {
    if (this.afwInput) {
      if (this.afwInput.elementRef.nativeElement.hasAttribute('required')) {
        if (this.afwInput.control) {
          const validator = this.afwInput.control.control?.hasValidator(Validators.required);
          if (!validator) {
            this.afwInput.control.control?.addValidators(Validators.required);
          }
        }
      }
    }
  }

  protected focusInput() {
    if (this.afwInput) {
      this.afwInput.elementRef.nativeElement.focus();
    }
  }

}
