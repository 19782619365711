<div class="afw-label-wrapper">
  <label>
    <ng-content select="afw-label" />
    <ng-content select="[afwLabel]" />

    @if (required) {
      *
    }
  </label>
</div>

<div class="afw-form-field-wrapper"
     #formWrapper
     [ngClass]="{
        error: afwInput?.control?.control?.touched && afwInput?.control?.control?.invalid,
        disabled: afwInput?.control?.control?.disabled || afwInput?.elementRef?.nativeElement.hasOwnProperty('disabled')
     }"
     (click)="focusInput()"
>
  <div class="afw-form-field-content" #content>
    @if (prefix) {
      <div class="afw-input-prefix">
        <ng-content select="[afwPrefix]" />
      </div>
    }
    <ng-content />
    @if (suffix) {
      <div class="afw-input-suffix">
        <ng-content select="[afwSuffix]" />
      </div>
    }
  </div>
</div>
@if (withError()) {
  <div class="afw-form-field-error">
    @if(afwInput?.control?.control?.touched && afwInput?.control?.control?.invalid) {
      <ng-content select="afw-error" />
    }
  </div>
}

