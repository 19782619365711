import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIcon } from '@ng-icons/core';

@Component({
  selector: 'afw-accordion-header',
  standalone: true,
  imports: [CommonModule, NgIcon],
  templateUrl: './afw-accordion-header.component.html',
  styleUrl: './afw-accordion-header.component.scss',
})
export class AfwAccordionHeaderComponent {}
