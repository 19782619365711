import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'afw-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './afw-option.component.html',
  styleUrl: './afw-option.component.scss',
})
export class AfwOptionComponent implements OnInit {
  @Input() value: any;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  selected: WritableSignal<boolean> = signal(false);

  @HostBinding('class.selected')
  isSelected = false;
  constructor(
    public elementRef: ElementRef
  ) {
    effect(
      () => {
        this.isSelected = this.selected();
      }
    )
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('click', () => {
      this.clickEvent.emit(this);
    });
  }

}
