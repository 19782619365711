import {
  AfterContentInit,
  booleanAttribute,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { AfwOptionComponent } from './afw-options/afw-option.component';
import { debounceTime } from 'rxjs';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroChevronDown } from '@ng-icons/heroicons/outline';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FormFieldComponent } from '../form-field/form-field.component';
import { InputDirective } from '../input/input.directive';
import { SuffixDirective } from '../input/suffix.directive';
import { lucideArrowDown, lucideChevronDown, lucideSearch } from '@ng-icons/lucide';

@Component({
  selector: 'afw-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgIcon, CdkOverlayOrigin, CdkConnectedOverlay, FormFieldComponent, InputDirective, SuffixDirective],
  templateUrl: './afw-select.component.html',
  styleUrl: './afw-select.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [
    provideIcons({lucideChevronDown, lucideSearch}),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AfwSelectComponent,
      multi: true
    }
  ]

})
export class AfwSelectComponent implements AfterContentInit, ControlValueAccessor {

  @HostBinding('class')
  hostClass = 'afw-select';

  @Input()
  formControlName: string = '';
  @Input()
  formControl: FormControl | undefined;
  @Input({ transform: booleanAttribute })
  withFilter = false;
  @Input()
  placeholderFilter = 'Filtrar';
  @Input()
  compareWithProperty!: string;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterValueChanges: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective | undefined;
  @ViewChild('input')
  input: ElementRef | undefined;
  @ViewChild('panel')
  optionsPanel!: ElementRef;
  @ContentChildren(AfwOptionComponent, { descendants: true })
  options: QueryList<AfwOptionComponent> = new QueryList<AfwOptionComponent>();

  opened = false;
  selectedLabel: string = '';
  filterControl: FormControl = new FormControl('');

  constructor(
    @Optional()
    private controlContainer: ControlContainer,
    public elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    @Optional() protected _parentFormField: FormFieldComponent
  ) {
  }

  get control(): any {
    return this.formControl ||
      (this.controlContainer &&
        this.controlContainer.control && this.controlContainer.control.get(this.formControlName)
      );
  }

  ngAfterContentInit() {
    this.subscribeClickEvent();
    this.options.changes.subscribe(
      c => {
        this.subscribeClickEvent();
      }
    );
  }

  subscribeClickEvent() {
    if (this.control) {
      if (this.control.value) {
        this.selectByValue(this.control.value);
      } else {
        this.selectedLabel = '';
        this.options.forEach(item => {
          item.selected.set(false);
        });
      }

    }
    this.options.forEach((item) => {
      item.clickEvent.subscribe(
        (value) => {
          this.selectOption(value, true);
        }
      );
    });
  }

  @HostListener('document:click', ['$event'])
  outside(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.opened = false;
      this.addFocusClass();
    }
  }

  open(): void {
    this.opened = !this.opened;
    this.control.markAsTouched();
    this.addFocusClass();
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
        this.filterControl.valueChanges.pipe(
          debounceTime(300)
        ).subscribe(
          (value) => {
            this.filterValueChanges.emit(value);
          }
        );
      }
    }, 0);
  }

  private addFocusClass() {
    if (this._parentFormField) {
      if (this.control.valid) {
        if (this.opened) {
          this._parentFormField.content.nativeElement.classList.add('opened');
        } else {
          this._parentFormField.content.nativeElement.classList.remove('opened');
        }
      }
    }
  }

  selectOption(option: AfwOptionComponent, setControlValue?: boolean): void {
    if (option) {
      option.selected.set(true);
      this.selectedLabel = option.elementRef.nativeElement.textContent;
      if (this.control && setControlValue) {
        this.control.setValue(option.value);
      }
      this.selectionChange.emit(option.value);
      this.options.forEach(item => {
        if (option !== item) {
          item.selected.set(false);
        }
      });
    }

  }

  writeValue(obj: any): void {
    if (obj) {
      if (this.options) {
        this.selectByValue(obj);
      }
    }
  }

  selectByValue(value: any) {
    const optionFounded = this.options.find(
      item => this.compareWithProperty ?
        item.value[this.compareWithProperty] === value[this.compareWithProperty] :
        item.value === value);
    if (optionFounded) {
      this.selectOption(optionFounded);
    }
  }

  registerOnChange(fn: any): void {
    if (this.formControlDirective && this.formControlDirective.valueAccessor) {
      this.formControlDirective.valueAccessor.registerOnChange(fn);
    }
  }

  registerOnTouched(fn: any): void {
    if (this.formControlDirective && this.formControlDirective.valueAccessor) {
      this.formControlDirective.valueAccessor.registerOnTouched(fn);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.formControlDirective && this.formControlDirective.valueAccessor) {
      this.formControlDirective.valueAccessor.setDisabledState?.(isDisabled);
    }
  }

}
