import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'afw-sidebar-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sidebar-container.component.html',
  styleUrl: './sidebar-container.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SidebarContainerComponent {

  @HostBinding('class')
  hostClass = 'afw-sidebar-container';

}
