import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'afw-accordion-body',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './afw-accordion-body.component.html',
  styleUrl: './afw-accordion-body.component.scss',
})
export class AfwAccordionBodyComponent {}
