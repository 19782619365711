<div (click)="open()" class="afw-select-trigger" cdk-overlay-origin #origin="cdkOverlayOrigin"
     [ngClass]="{opened: opened}" tabindex="0">
  <div class="afw-option-wrapper">
    <div class="afw-option">
      <span class="option-selected">{{ selectedLabel }}</span>
    </div>
    <ng-icon name="lucideChevronDown" class="arrow" [ngClass]="{opened: opened}" />
  </div>
</div>
<ng-template
  cdk-connected-overlay
  [cdkConnectedOverlayOrigin]="_parentFormField.formWrapper || origin"
  [cdkConnectedOverlayOpen]="opened"
>
  <div #panel class="options-panel"
       [style.width]="(_parentFormField.formWrapper || origin.elementRef).nativeElement.getBoundingClientRect().width + 'px'">
    @if (withFilter) {
      <div class="search-input">
        <afw-form-field withError="false">
          <input afwInput #input (click)="$event.stopPropagation()" [formControl]="filterControl"
                 [placeholder]="placeholderFilter" />
          <ng-icon name="lucideSearch" afwSuffix />
        </afw-form-field>
      </div>
    }
    <ng-content></ng-content>
  </div>
</ng-template>
